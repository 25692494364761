import axios from 'axios';

export function loadReviews() {
    return (dispatch) => {
        return axios.get("http://localhost:1458/api/values").then((response) => {
            dispatch(readReviews(response.reviews));
        })
    }
}

export function loadLocalReviews() {
    return (dispatch) => {
        return fetch("data/customer-reviews.json")
            .then(r => r.json())
            .then(data => {
                dispatch(readReviews(data.reviews));
            })
    }
}

export function readReviews(reviews) {
    return {
        type: "READ_REVIEWS",
        payload: reviews
    }
}

export function loadLocalContacts() {
    return (dispatch) => {
        return fetch("data/contacts.json")
            .then(r => r.json())
            .then(data => {
                dispatch(readContacts(data.contacts));
            })
    }
}

export function readContacts(contacts) {
    return {
        type: "READ_CONTACTS",
        payload: contacts
    }
}

export function loadLocalPrice() {
    return (dispatch) => {
        return fetch("data/price-list.json")
            .then(r => r.json())
            .then(data => {
                dispatch(readPriceList(data.priceItems));
            })
    }
}

export function readPriceList(priceItems) {
    return {
        type: "READ_PRICE",
        payload: priceItems
    }
}

const proxyurl = "https://cors-anywhere.herokuapp.com/";
const url = "http://api.snp-grupp.ru/api/mail";
export function sendMail(mail) {
    return (dispatch) => {
        return axios.post(proxyurl+url, {
            Contact: mail.contact,
            Email: mail.email,
            Phone: mail.phone,
            Message: mail.message,
        })
    }
}

export function clearMail() {
    return {
        type: "CLEAR_MAIL",
        payload: null
    }
}