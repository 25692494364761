import React from 'react';
import { Parallax } from 'react-parallax';

export default function ParallaxAbout(props) {
    return <Parallax className="parallax-window" bgImage={'../img/1920x1080/01.jpg'}>        
        <div className="parallax-content container">
            <h1 className="carousel-title">О нас</h1>
            <p>Оптовая реализация нефтепродуктов<br />по Москве и Центральной части РФ</p>
        </div>
    </Parallax>
}