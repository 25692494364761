import React from 'react';
import Header from '../components/header';
import ParallaxAbout from '../components/parallax-about';
import About from '../components/about';
import Service from '../components/service';
import Footer from '../components/footer';

export default function AboutPage(props){
    return <React.Fragment>
        <Header />
        <ParallaxAbout />
        <About />
        <Service />
        <Footer /> 
    </React.Fragment>    
}