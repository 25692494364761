import React from 'react';
import ContactItem from './contact-item';

export default function ContactItems(props) {
    return <div className="section-seperator">
        <div className="content-lg container">
            <div className="row">
                {
                    props.contacts.map(item => <ContactItem contact={item} key={item.id} />)
                }
            </div>
        </div>
    </div>
}