import React from 'react';
import { Parallax } from 'react-parallax';

export default function ParallaxContact(props) {
    return <Parallax className="parallax-window" bgImage={'../img/1920x1080/01.jpg'}>
        <div className="parallax-content container">
            <h1 className="carousel-title">Контакты</h1>
            <p>Общество с ограниченной ответственностью СНП-ГРУПП</p>
        </div>
    </Parallax>
}