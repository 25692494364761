import React from 'react';
import { Parallax } from 'react-parallax';

export default function ParallaxPrice(props) {
    return <Parallax className="parallax-window" bgImage={'../img/1920x1080/01.jpg'}>
        <div className="parallax-content container">
            <h1 className="carousel-title">Доставка</h1>
            <p>Доставка дизельного топлива по Москве<br />и Центральной части РФ</p>
        </div>
    </Parallax>
}