import React from 'react';

export default function ContactItem(props) {
    var phone;
    if (props.contact.phone !== "") {
        phone = <li><i className="margin-r-10 color-base icon-call-out"></i> {props.contact.phone}</li>
    }

    var email;
    if (props.contact.email !== "") {
        email = <li><i className="margin-r-10 color-base icon-envelope"></i> {props.contact.email}</li>
    }

    return <div className="col-sm-4 sm-margin-b-50">
        <div className="wow fadeInLeft" data-wow-duration=".3" data-wow-delay=".3s">
            <h3>{props.contact.city} <span className="text-uppercase margin-l-20">{props.contact.type}</span></h3>
            <p>{props.contact.details}</p>
            <ul className="list-unstyled contact-list">
                {phone}
                {email}
            </ul>
        </div>
    </div>
}