import React from 'react';

export default function Delivery(props) {
    return <React.Fragment>
        <div className="content-lg container">
            <div className="row">
                <div className="col-sm-12 sm-margin-b-50">
                    <div className="margin-b-30">
                        <p>Топливная компания «СНП-ГРУПП» оперативно и в полном объеме доставит необходимый Вам объем топлива в Москву и любой населенный пункт, расположенный в Центральной части РФ.</p>
                        <p>Собственный парк современных и технически обслуженных автомобилей гарантирует бесперебойную поставку нефтепродуктов круглый год вне зависимости от времени суток и погодных условий.</p>
                        <p>Наш автопарк насчитывает 7 бензовозов объемом от 1 до 32 м<sup><small>3</small></sup>. Вся техника имеет необходимые свидетельства о проверках с указанием даты их проведения. Наши бензовозы оснащены всем необходимым оборудованием, которое включает в себя топливные насосы и счётчики, а также шланги длиной до 30 метров.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="promo-section overflow-h">
            <div className="container">
                <div className="clearfix">
                    <div className="ver-center">
                        <div className="ver-center-aligned">
                            <div className="promo-section-col">
                                <h2>Для осуществления доставки необходимо:</h2>
                                <p>1. Оформить заявку (у менеджера, отправить на почту, Viber, WhatsApp).</p>
                                <p>2. Следующим этапом является погрузка топлива в цистерну, опломбирование всех технологических отверстий автоцистерн, а также передача отгрузочных и транспортных документов водителю.</p>
                                <p>3. По прибытию бензовоза на место водитель передает клиенту все необходимые документы и производит слив топлива в емкости клиента.</p>
                                <p>4. Стоимость доставки определяется по согласованию с нашими менеджерами и может быть включена как в цену топлива, так и оплачена отдельно.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="promo-section-img-right">
                <img className="img-responsive" src="../img/delivery/delivery 1.jpg" alt="delivery" />
            </div>
        </div>
    </React.Fragment>
}