import React from 'react';

export default function About(props) {
    return <div className="content-lg container">
        <div className="row">
            <div className="col-sm-11 sm-margin-b-50">
                <div className="margin-b-30">
                    <p>Компания «СНП-ГРУПП» осуществляет оптовую и мелкооптовую реализацию нефтепродуктов различных сортов по Москве и Центральной части РФ.</p>
                    <p>Наша компания уже успела зарекомендовать себя как надежный поставщик, который работает и удовлетворяет потребности клиента и безусловно соблюдает качество поставляемого топлива.</p>
                    <p>Сотрудники «СНП-ГРУПП» имеют большой опыт и высокую квалификацию. Наличие собственного парка автомобильной техники позволяет своевременно и качественно доставить нефтепродукты нашим клиентам. Деятельность компании включает в себя все этапы доставки и продажи нефтепродуктов для клиента без каких-либо посредников, мы способны предложить конкурентоспособную цену на все виды нефтепродуктов.</p>
                    </div>                                    
            </div>            
        </div>
    </div>
}