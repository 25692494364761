import React from 'react';
import PriceListItem from './price-list-item';
import './style.css';

export default function PriceList(props) {
    return <div className="content-lg container">
        <table className="table-fill">
            <thead>
                <tr>
                    <th className="text-left">Вид топлива</th>
                    <th className="text-left">Стоимость за литр</th>
                </tr>
            </thead>
            <tbody className="table-hover">
                {
                    props.priceItems.map(priceItem => <PriceListItem priceItem={priceItem} key={priceItem.id} />)
                }
            </tbody>
        </table>
    </div>
}