import React from 'react';
import {Link} from 'react-router-dom';
import MailForm from '../features/mail-form/index';

export default function Footer(props){
    return <footer className="footer">
    <div className="footer-seperator">
        <div className="content-lg container">
            <div className="row">
                <div className="col-sm-2 sm-margin-b-50">
                    <ul className="list-unstyled footer-list">
                        <li className="footer-list-item"><Link className="footer-list-link" to="/">Главная</Link></li>
                        <li className="footer-list-item"><Link className="footer-list-link" to="/price">Цены</Link></li>
                        <li className="footer-list-item"><Link className="footer-list-link" to="/delivery">Доставка</Link></li>
                        <li className="footer-list-item"><Link className="footer-list-link" to="/about">О нас</Link></li>
                        <li className="footer-list-item"><Link className="footer-list-link" to="/contact">Контакты</Link></li>
                    </ul>
                </div>
                <div className="col-sm-4 sm-margin-b-30">
                    <ul className="list-unstyled footer-list">
                        <li className="footer-list-item"><p className="footer-list-link">Телефон: +7 (925) 331 74 84</p></li>
                        <li className="footer-list-item"><p className="footer-list-link">Email: snp-gr18@mail.ru</p></li>
                    </ul>
                </div>
                <MailForm />
            </div>
        </div>
    </div>
    <div className="content container">
        <div className="row">
            <div className="col-xs-6">
                <img className="footer-logo" src="img/logo.png" alt="СНП-ГРУПП" />
            </div>            
        </div>
    </div>
</footer>
}