import React from 'react';

export default function CustomerReviewItem(props){
    return <div className="swiper-slide">
    <blockquote className="blockquote">
        <div className="margin-b-20">
            {props.review.comment}
        </div>        
        <p><span className="fweight-700 color-link">{props.review.name}</span>, {props.review.company}</p>
    </blockquote>
</div>
}