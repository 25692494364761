import React from 'react';
import Header from '../components/header';
import ParallaxContact from '../components/parallax-contact';
import Contacts from '../features/contact-items/index';
import Footer from '../components/footer';
import CustomerReviews from '../features/customer-reviews/index';
import { connect } from 'react-redux';
import * as actionCreators from '../actions/index.js';

class ContactPage extends React.Component {
    constructor(props){
        super(props); 

        if (this.props.contacts.length === 0) {
            this.props.loadLocalContacts();
        }

        if (this.props.reviews.length === 0) {
            this.props.loadLocalReviews();
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <ParallaxContact />
                <Contacts contacts={this.props.contacts} />
                <CustomerReviews reviews={this.props.reviews} />
                <Footer />
            </React.Fragment>
        )
    }
}

const mapStateToProps=(state)=>{
    return state
};

export default connect(mapStateToProps, actionCreators)(ContactPage);