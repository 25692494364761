import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import HomePage from './pages/home-page';
import PriceListPage from './pages/price-list-page';
import DeliveryPage from './pages/delivery-page';
import AboutPage from './pages/about-page';
import ContactPage from './pages/contact-page';

const Router = () => (
    <Switch>
        <Route exact path='/' component={HomePage} />
        <Route path='/price' component={PriceListPage} />
        <Route path='/delivery' component={DeliveryPage} />
        <Route path='/about' component={AboutPage} />
        <Route path='/contact' component={ContactPage} />
        <Redirect to='/' />
    </Switch>
)

export default Router