import React from 'react';
import Header from '../components/header';
import ParallaxDelivery from '../components/parallax-delivery';
import Delivery from '../components/delivery';
import Footer from '../components/footer';

export default function DelivaryPage(props){
    return <React.Fragment>
        <Header />
        <ParallaxDelivery />
        <Delivery />
        <Footer /> 
    </React.Fragment>    
}