import React from 'react';
import {Link} from 'react-router-dom';

export default function Header(props){
    return <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
    <div className="container">        
        <ol className="carousel-indicators">
            <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
            <li data-target="#carousel-example-generic" data-slide-to="1" ></li>
        </ol>
    </div>    
    <div className="carousel-inner" role="listbox">
        <div className="item active">
            <img className="img-responsive" src="../img/1920x1080/01.jpg"  alt="Slider"/>
            <div className="container">
                <div className="carousel-centered">
                    <div className="margin-b-40">
                        <h1 className="carousel-title">Лучшие цены</h1>
                        <p className="color">У нас самые честные и гибкие условия и цены,<br/>изучите наш прайс лист, чтобы убедиться в этом.</p>
                    </div>
                    <Link to="/price" className="btn-theme btn-theme-sm btn-white-brd text-uppercase">Узнать</Link>
                </div>
            </div>
        </div>
        <div className="item">
            <img className="img-responsive" src="../img/1920x1080/02.jpg"  alt="Slider" />
            <div className="container">
                <div className="carousel-centered">
                    <div className="margin-b-40">
                        <h2 className="carousel-title">Быстрая доставка</h2>
                        <p className="color">Мы постовляем нашу продукцию согласно расписанию<br/>без задержек и перебоев</p>
                    </div>
                    <Link to="/delivery" className="btn-theme btn-theme-sm btn-white-brd text-uppercase">Условия</Link>
                </div>
            </div>
        </div>
    </div>
</div>
}