import React from 'react';
import Header from '../components/header';
import Slider from '../components/slider';
import Service from '../components/service';
import PriceList from '../features/price-list/index';
import CustomerReviews from '../features/customer-reviews/index';
import Footer from '../components/footer';
import priceData from '../data/price-list.json'

import { connect } from 'react-redux';
import * as actionCreators from '../actions/index.js';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.priceItems.length === 0) {
            this.props.loadLocalPrice();
        }

        if (this.props.reviews.length === 0) {
            this.props.loadLocalReviews();
        }
    }

        render() {
            return (
                <React.Fragment>
                    <Header />
                    <Slider />
                    <PriceList priceItems={this.props.priceItems} />
                    <Service />
                    <CustomerReviews reviews={this.props.reviews} />
                    <Footer />
                </React.Fragment>
            )
        }
    }

    const mapStateToProps = (state) => {
        return state
    };

    export default connect(mapStateToProps, actionCreators)(HomePage);
