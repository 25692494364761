let defaultState = {
    reviews: [
        /*{
            "id": 1,
            "name": "Женя",
            "comment": "Норм поставщики",
            "company": "MVVM group"
        }*/
    ],
    contacts: [
        /*{
            "id": 1,
            "city": "Москва",
            "type": "юр. адрес",
            "details": "Юридический адрес",
            "phone": "+7 (925) 610 47 18",
            "email": "snp-gr18@mail.ru"
        }*/
    ],
    priceItems: [
        /*{
            "id": 1,
            "name": "Бензин АИ-92",
            "price": "40"
        }*/
    ]
}

const mainReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "READ_REVIEWS":
            return {
                ...state,
                reviews: action.payload
            }
        case "READ_CONTACTS":
            return {
                ...state,
                contacts: action.payload
            }
        case "READ_PRICE":
            return {
                ...state,
                priceItems: action.payload
            }        
        default:
            return {
                ...state
            }
    }
}

export default mainReducer;