import React from 'react';
import {Link} from 'react-router-dom';

export default function Header(props){
    return <header className="header navbar-fixed-top"> 
    <nav className="navbar" role="navigation">
        <div className="container">            
            <div className="menu-container">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".nav-collapse">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="toggle-icon"></span>
                </button>                
                <div className="logo">
                    <Link className="logo-wrap" to="/">
                        <img className="logo-img logo-img-main" src="../img/logo.png" alt="Asentus Logo" />
                        <img className="logo-img logo-img-active" src="../img/logo-dark.png" alt="Asentus Logo" />
                    </Link>
                </div>                
            </div>
            <div className="collapse navbar-collapse nav-collapse">
                <div className="menu-container">
                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item"><Link className="nav-item-child nav-item-hover active" to="/">Главная</Link></li>
                        <li className="nav-item"><Link className="nav-item-child nav-item-hover" to="/price">Цены</Link></li>
                        <li className="nav-item"><Link className="nav-item-child nav-item-hover" to="/delivery">Доставка</Link></li>
                        <li className="nav-item"><Link className="nav-item-child nav-item-hover" to="/about">О нас</Link></li>
                        <li className="nav-item"><Link className="nav-item-child nav-item-hover" to="/contact">Контакты</Link></li>
                    </ul>
                </div>
            </div>            
        </div>
    </nav>    
</header>
}