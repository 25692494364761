import React from 'react';
import CustomerReviewItem from './customer-review-item';

export default function CustomerReviews(props) {
    return <div className="content-lg container">
        <div className="row">
            <div className="col-sm-9">
                <h2>Отзывы клиентов</h2>
                <div className="swiper-slider swiper-testimonials">
                    <div className="swiper-wrapper">
                        {
                            props.reviews.map(review => <CustomerReviewItem review={review} key={review.id} />)
                        }
                    </div>
                    <div className="swiper-testimonials-pagination"></div>
                </div>
            </div>
        </div>
    </div>
}