import React from 'react';

export default function Service(props){
    return <div className="bg-color-sky-light" data-auto-height="true">
    <div className="content-lg container">
        <div className="row row-space-1 margin-b-2">
            <div className="col-sm-4 sm-margin-b-2">
                <div className="wow fadeInLeft" data-wow-duration=".3" data-wow-delay=".3s">
                    <div className="service" data-height="height">
                        <div className="service-element">
                            <i className="service-icon icon-chemistry"></i>
                        </div>
                        <div className="service-info">
                            <h3>Проверка качества</h3>
                            <p className="margin-b-5">Мы предоставляем опытные образцы для подтверждения качества продукта</p>
                        </div>                         
                    </div>
                </div>
            </div>
            <div className="col-sm-4 sm-margin-b-2">
                <div className="wow fadeInLeft" data-wow-duration=".3" data-wow-delay=".2s">
                    <div className="service" data-height="height">
                        <div className="service-element">
                            <i className="service-icon icon-screen-tablet"></i>
                        </div>
                        <div className="service-info">
                            <h3>Проверенные поставщики</h3>
                            <p className="margin-b-5">Основным поставщиков компании является Газпромнефть</p>
                        </div>   
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="wow fadeInLeft" data-wow-duration=".3" data-wow-delay=".1s">
                    <div className="service" data-height="height">
                        <div className="service-element">
                            <i className="service-icon icon-badge"></i>
                        </div>
                        <div className="service-info">
                            <h3>Сертификаты качества</h3>
                            <p className="margin-b-5">Мы выполняем поставку только сертифицированного продукта</p>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div className="row row-space-1">
            <div className="col-sm-4 sm-margin-b-2">
                <div className="wow fadeInLeft" data-wow-duration=".3" data-wow-delay=".4s">
                    <div className="service" data-height="height">
                        <div className="service-element">
                            <i className="service-icon icon-notebook"></i>
                        </div>
                        <div className="service-info">
                            <h3>Соблюдение контракта</h3>
                            <p className="margin-b-5">Предоставление услуг совершается согласно заключенному договору</p>
                        </div>   
                    </div>
                </div>
            </div>
            <div className="col-sm-4 sm-margin-b-2">
                <div className="wow fadeInLeft" data-wow-duration=".3" data-wow-delay=".5s">
                    <div className="service" data-height="height">
                        <div className="service-element">
                            <i className="service-icon icon-speedometer"></i>
                        </div>
                        <div className="service-info">
                            <h3>Быстрая доставка</h3>
                            <p className="margin-b-5">Мы гарантируем выполнение поставок строго по согласованному расписанию</p>
                        </div>  
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="wow fadeInLeft" data-wow-duration=".3" data-wow-delay=".6s">
                    <div className="service" data-height="height">
                        <div className="service-element">
                            <i className="service-icon icon-badge"></i>
                        </div>
                        <div className="service-info">
                            <h3>Качество услуг</h3>
                            <p className="margin-b-5">Мы постоянно заботимся о повышении качества предоставляемых услуг</p>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}