import React from 'react';
import Header from '../components/header';
import ParallaxPrice from '../components/parallax-price';
import PriceList from '../features/price-list/index';
import Footer from '../components/footer';

import { connect } from 'react-redux';
import * as actionCreators from '../actions/index.js';

class PriceListPage extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.priceItems.length === 0) {
            this.props.loadLocalPrice();
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <ParallaxPrice />
                <PriceList priceItems={this.props.priceItems} />
                <Footer />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return state
};

export default connect(mapStateToProps, actionCreators)(PriceListPage);
