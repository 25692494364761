import React from 'react';

import { connect } from 'react-redux';
import * as actionCreators from '../../actions/index.js';

class MailForm extends React.Component {
    constructor(props) {        
        super(props);

        this.state = {
            "contact" : "",
            "email" : "",
            "phone" : "",
            "message" : "",
        };
                 
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
          });
      }

      handleSubmit(event) {
        this.props.sendMail(this.state)
        this.setState({
            "contact" : "",
            "email" : "",
            "phone" : "",
            "message" : "",
        });
        event.preventDefault();
      }

    render() {
        return (
            <div className="col-sm-5 sm-margin-b-30">
                <h2 className="color-white">Обратная связь</h2>
                <input name="contact" type="text" className="form-control footer-input margin-b-20" placeholder="Имя" required value={this.state.contact} onChange={this.handleChange} />
                <input name="email" type="email" className="form-control footer-input margin-b-20" placeholder="Email" required value={this.state.email} onChange={this.handleChange}/>
                <input name="phone" type="text" className="form-control footer-input margin-b-20" placeholder="Телефон" required value={this.state.phone} onChange={this.handleChange}/>
                <textarea name="message" className="form-control footer-input margin-b-30" rows="6" placeholder="Сообщение" required value={this.state.message} onChange={this.handleChange}/>
                <button className="btn-theme btn-theme-sm btn-base-bg text-uppercase" onClick={this.handleSubmit}>Отправить</button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state
};

export default connect(mapStateToProps, actionCreators)(MailForm);